import { useRef, useState } from "react";
import { API_URL } from "../config/config";
import "../css/FileUpload.css"


function FileUploadButton({ curChat, setIdandTitle }) {

  const refFile = useRef(null);
  const handleOnClick = (event) => {
    event.preventDefault(); // 防止表单默认提交行为
    refFile.current.click();
  }
  const token = localStorage.getItem('token');

  const [suc, setSuc] = useState(1);

  const handleOnChange = async (event) => {
    // upload_suc = 0;

    const slicedStr = "上传文件..."
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setSuc(0);
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const allowed_extension = ['pdf', 'doc', 'docx']
    if (!allowed_extension.includes(fileExtension)) {
      alert("请上传doc、docx、pdf类型的文件!");
      return;
    }
    const MAX_SIZE = 5 * 1024 * 1024;
    if (file.size > MAX_SIZE) {
      alert("请上传5M以下的文件!");
      return;
    }

    if (curChat.threadId === -1) {
      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${API_URL}/chatbot/create_thread`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            userId: userId,
            title: slicedStr,
            assistantId: "asst_l5NTTbMmeqlFVJNKqIGDohYP",
          }),
        },
      );
      //This happens when the token expires or the user did not login
      //We use 400 to state this situation
      if (response.status === 400) {
        window.location.href = "/login";
        return null;
      }
      else {
        //Set the threadId and the title into the NewChat
        const responseData = await response.json();
        //deleteNew: the info has been added into the curChat
        //in the next line, these info will be added into the addChats_ together
        await setIdandTitle(responseData.threadId, slicedStr);
      }
    }


    const formData = new FormData();
    formData.append('file', file);
    formData.append('threadId', curChat.threadId); // 包含其他需要传递的信息  

    const response0 = await fetch(`${API_URL}/files/upload_file_to_openai`,
      {
        method: "POST",
        headers: {
          // "Content-type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        },
        body: formData
      }
    )
    // upload_suc = 1;
    setSuc(1);
    if (response0.status === 200) {
      alert("上传文件成功！");
    }
    else if (response0.status === 400) {
      alert("Bad Request");
    }
    else if (response0.status === 500) {
      alert("Internal server error");
    }
  }


  return (
    <form id="uploadForm" enctype="multipart/form-data">
      <button onClick={handleOnClick} className="upload-button" disabled={suc == 0}>
        {
          suc == 0 ?
            <svg t="1718591289965" class="load" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14945" width="24" height="24"><path d="M512 322.56a35.84 35.84 0 0 1-35.84-35.84V102.4a35.84 35.84 0 1 1 71.68 0v184.32a35.84 35.84 0 0 1-35.84 35.84zM512 957.44a35.84 35.84 0 0 1-35.84-35.84v-184.32a35.84 35.84 0 1 1 71.68 0v184.32a35.84 35.84 0 0 1-35.84 35.84zM645.95456 378.04544a35.83488 35.83488 0 0 1 0-50.68288l130.33472-130.33472a35.83488 35.83488 0 0 1 50.68288 0 35.83488 35.83488 0 0 1 0 50.68288l-130.33472 130.33472a35.83488 35.83488 0 0 1-50.68288 0zM197.02784 826.97216a35.83488 35.83488 0 0 1 0-50.68288l130.33472-130.33472a35.83488 35.83488 0 0 1 50.68288 0 35.83488 35.83488 0 0 1 0 50.68288l-130.33472 130.33472a35.83488 35.83488 0 0 1-50.68288 0zM701.44 512a35.84 35.84 0 0 1 35.84-35.84h184.32a35.84 35.84 0 1 1 0 71.68h-184.32a35.84 35.84 0 0 1-35.84-35.84zM66.56 512a35.84 35.84 0 0 1 35.84-35.84h184.32a35.84 35.84 0 1 1 0 71.68H102.4a35.84 35.84 0 0 1-35.84-35.84zM645.95456 645.95456a35.83488 35.83488 0 0 1 50.68288 0l130.33472 130.33472a35.83488 35.83488 0 0 1 0 50.68288 35.83488 35.83488 0 0 1-50.68288 0l-130.33472-130.33472a35.83488 35.83488 0 0 1 0-50.68288zM197.02784 197.02784a35.83488 35.83488 0 0 1 50.68288 0l130.33472 130.33472a35.83488 35.83488 0 0 1 0 50.68288 35.83488 35.83488 0 0 1-50.68288 0L197.02784 247.71072a35.83488 35.83488 0 0 1 0-50.68288z" fill="#707070" p-id="14946"></path></svg>
            :
            <svg t="1717843334879" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7143" width="24" height="24"><path d="M780.8 166.4V742.4c0 92.928-56.32 268.8-268.8 268.8S243.2 835.328 243.2 742.4V204.8a230.4 230.4 0 0 1 23.296-93.952A179.2 179.2 0 0 1 435.2 12.8a179.2 179.2 0 0 1 168.704 98.048A228.608 228.608 0 0 1 627.2 204.8v499.2A111.872 111.872 0 0 1 512 819.2a111.872 111.872 0 0 1-115.2-115.2V358.4a38.4 38.4 0 0 1 76.8 0v345.6A35.072 35.072 0 0 0 512 742.4a35.328 35.328 0 0 0 38.4-38.912V204.8A109.568 109.568 0 0 0 435.2 89.6a102.4 102.4 0 0 0-100.096 55.552A158.976 158.976 0 0 0 320 204.8v537.6c0 19.456 6.912 192 192 192 189.952 0 192-184.064 192-192V166.4a38.4 38.4 0 0 1 76.8 0" fill="#030000" p-id="7144"></path></svg>
        }
      </button>
      <input type="file" style={{ display: 'none' }} onChange={handleOnChange} ref={refFile} accept=".pdf,.doc,.docx"></input>
    </form>
  )
}

export default FileUploadButton
