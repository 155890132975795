import React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { API_URL } from '../config/config';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

function FileUploadComponent() {

  const token = localStorage.getItem("token");

  const handleProcess = async (fieldName, file, metadata, load, error, progress, abort) => {
    // 从后端获取预签名 URL
    const response = await fetch(`${API_URL}/files/upload_file?fileName=${encodeURIComponent(file.name)}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    const data = await response.json();

    const uploadRequest = new XMLHttpRequest();
    uploadRequest.open('PUT', data.url);
    uploadRequest.setRequestHeader('Content-Type', file.type);  // 设置 Content-Type

    uploadRequest.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    uploadRequest.onload = () => {
      if (uploadRequest.status >= 200 && uploadRequest.status < 300) {
        load(uploadRequest.responseText);
      } else {
        error('Upload failed');
      }
    };

    uploadRequest.onerror = () => {
      error('Upload error');
    };

    uploadRequest.send(file);  // 直接发送文件对象
    return {
      abort: () => {
        uploadRequest.abort();
        abort();
      }
    };
  };

  return (
    <FilePond
      allowMultiple={true}
      maxFiles={3}
      server={{ process: handleProcess }}
      name="files"
      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
    />
  );
}

export default FileUploadComponent;
