import { API_URL } from "../config/config";

async function create_thread({slicedStr}){
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const response = await fetch(`${API_URL}/chatbot/create_thread`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: userId,
        title: slicedStr,
        assistantId: "asst_l5NTTbMmeqlFVJNKqIGDohYP",
      }),
    });
    return response;
}

export default create_thread;