import { API_URL } from '../config/config';
async function clearDialogs() {
  try {

    // get the userId from localStorage
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const response = await fetch(
      `${API_URL}/chatbot/delete_thread_all/${userId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      },
    );
    if (response.status === 400) {
      alert("非法用户");
      window.location.href = "/login";
      console.error("请求失败:", response.statusText);
      return null;
    }
    else {
      console.log("删除成功!");
    }
  } catch (error) {
    console.error("请求错误:", error);
    return null;
  }
}

export default clearDialogs;
