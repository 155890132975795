import { API_URL } from '../config/config';
async function Logout() {
  try {
    // get userId from localStorage
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const logoutInfo = {
      userName: userId
    };
    const response = await fetch(
      `${API_URL}/chatbot/logout`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(logoutInfo),
      },
    );
    if (response.status === 400) {
      alert("非法用户");
      window.location.href = "/login";
      console.error("请求失败:", response.statusText);
      return null;
    }
    else {

      let data = await response.json();
      if (data.success) {
        localStorage.removeItem("token");
        alert("退出成功!");
        window.location.href = "/login";
      }
    }
  } catch (error) {
    console.error("请求错误:", error);
    return null;
  }
}

export default Logout;
