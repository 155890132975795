import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/LoginPage.css";
import fetchDialogs from "./fetchDialogs"; // 导入 fetchDialogs 函数
import { API_URL } from '../config/config';

function LoginPage({ setDialogs }) {
  const [userId, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    const loginInfo = {
      userName: userId,
      password: password,
    };

    try {
      const response = await fetch(`${API_URL}/chatbot/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginInfo),
      }, { mode: 'no-cors' });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("token", data.token);  // store the token
        localStorage.setItem("realName", data.realName);
        localStorage.setItem("portrait", data.portrait);
        localStorage.setItem("userPosition", data.userPosition);
        const dialogs = await fetchDialogs();
        setDialogs(dialogs);  //update the dialog using "fetchDialogs"
        navigate("/app");
      } else {
        if (response.status == 401) {
          alert("账号或密码错误!");
        }
        else if (response.status == 403) {
          alert("该账号已在其他设备登录!");
        }
        else if (response.status == 500) {
          alert("登录失败，服务器繁忙!");
        }
        else {
          alert("登录失败!");
        }
      }

    } catch (error) {
      console.error("登录错误:", error);
    }
  };


  // 检查用户是否已经登录
  useEffect(() => {
    const checkLoggedIn = async () => {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      if (!token || !userId) {
        return;
      }

      try {
        const response = await fetch(`${API_URL}/chatbot/check_logged_in`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, userId }), // 将 token 和 userId 包装在一个对象中，并转换为 JSON 格式
        });

        if (response.status === 200) {
          const dialogs = await fetchDialogs();
          setDialogs(dialogs);  //update the dialog using "fetchDialogs"
          navigate("/app");
        }
      } catch (error) {
        console.error("检查登录状态错误:", error);
      }
    };

    checkLoggedIn();
  }, []); // 空数组作为依赖，表示这个 useEffect 只在组件加载时运行一次


  return (
    <div class="body1">
      <div class="container right-panel-active">

        <div class="container__form container--signup">
          <form action="#" class="form" id="form1" onClick={(e) => e.preventDefault()}>
            <h2 class="form__title">What is 洽特阿呆?</h2>
            <div id="chatContent">
              <span class="contentSpan">
                洽特阿呆是一种基于人工智能技术的聊天机器人，它由广泽软件开发部开发。它是建立在生成式预训练模型架构之上的，具有理解和生成自然语言的能力。洽特阿呆可以用于各种对话场景，包括辅助办公、数据查询、文字润色、闲聊、帮助和娱乐等。
                它通过对大量文本数据进行训练，学习了丰富的语言知识和语境，从而能够根据用户的输入生成自然流畅的回复。</span>
            </div>
            <foot class="foot">⭐ 这段对话是由洽特阿呆生成的</foot>
          </form>
        </div>


        <div class="container__form container--signin">
          <form action="#" class="form" id="form2" onClick={(e) => e.preventDefault()}>
            <svg width="52" height="45" viewBox="0 0 52 45" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M51.5128 44.4784H51.3274C47.5426 35.329 39.9709 26.4358 33.5491 20.2573C36.4088 18.8008 39.4898 17.4666 42.6484 16.3336C48.9393 24.3057 52.1258 34.3482 51.5128 44.4784ZM0.729901 44.4784H0.607201V13.8384C5.6763 13.7472 10.7761 13.1227 15.8057 11.7098C20.0708 14.093 24.132 16.8161 27.8175 20.0781C16.2303 26.133 9.0822 34.6059 0.729901 44.4784ZM0.607201 11.161V4.56122C4.6572 6.23682 8.7037 8.00442 12.6195 10.0077C9.0588 10.6322 5.0598 11.0587 0.607201 11.161ZM31.4706 18.3131C27.9564 15.1205 24.059 12.5572 19.9284 10.4297C23.3846 9.22882 26.776 7.64412 30.054 5.59572C34.3503 8.00662 38.2573 11.1601 41.5432 14.991C38.1051 15.7237 34.7449 16.8605 31.4706 18.3131ZM17.1223 9.06562C11.8308 6.63492 6.2272 4.81962 0.607201 3.24422V0.76292C10.251 -1.08548 20.2294 0.47282 28.7968 4.91662C25.9003 6.32692 22.0282 7.86552 17.1223 9.06562Z" fill="black" />
              <path d="M45.6639 44.4784H6.4292C13.622 36.1744 20.4287 27.7769 30.0518 22.1632C35.717 27.7051 42.6937 36.4077 45.6639 44.4784Z" fill="black" />
            </svg>
            <p>   </p>
            <h2 class="form__title">欢迎使用广泽洽特阿呆</h2>
            <input type="email" placeholder="用户名" class="input" value={userId} onChange={handleUsernameChange} />
            <input type="password" placeholder="密码" class="input" value={password} onChange={handlePasswordChange} />
            <button class="btn" onClick={handleLogin}><span class="buttonContent">登  录</span></button>
          </form>
        </div>

        <div class="container__overlay">
          <div class="overlay">
            <div class="overlay__panel overlay--left">
              <button class="btn" id="signIn"
                onClick={() => {
                  const container = document.querySelector(".container");
                  container.classList.remove("right-panel-active");
                }}>
                <span class="buttonContent">登  录</span></button>
            </div>
            <div class="overlay__panel overlay--right">
              <button class="btn" id="signUp"
                onClick={() => {
                  const container = document.querySelector(".container");
                  container.classList.add("right-panel-active");
                }}>
                <span class="buttonContent">什么是洽特阿呆?</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
