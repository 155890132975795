 import { max_char } from "../config/config";
 
 function checkCharsNums({message}) {
   var byteLength = 0;
   for (var i = 0; i < message.length; i++) {
     var curChar = message.charCodeAt(i);
     if (curChar > 0 && curChar <= 128) {
       byteLength += 1;
     } else {
       // UTF-8中文字符占3个字节
       byteLength += 2;
     }
   }
   return byteLength;
 }

 export default checkCharsNums;