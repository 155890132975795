import AddChat from "./addChat";
import uuid from "./uuid"
export default class addChats {
  constructor() {
    this.curChat = null;
    this.NewChat = {};
    this.curId = -3;//represents null
    this.NewChat["getHistory"] = "no";
    this.exclusiveStartKey = null;
    this.limit = 30;
    this.max_id = 0;
    this.timeStamp = {};
  }
  init(){
      this.curChat = null;
      this.NewChat = {};
      this.curId = -3;//represents null
      this.NewChat["getHistory"] = "no";
      this.exclusiveStartKey = null;
      this.limit = 30;
      this.max_id = 0;
      this.timeStamp = {};
  }
  //when you click the "add Chat" button, this fuction will be executed
  addChat(props) {
    const tmpId = uuid(8,10);
    this.curChat = new AddChat({
      father: props.father,
      setCurChat: props.setCurChat,
      fatherRenderFunc: props.fatherRenderFunc,
      handleRenderHistory: props.handleRenderHistory,
      id: props.id,
      threadId: props.threadId,
      title: props.title,
      choose: props.choose,
      persistId:tmpId
    });
    props.setCurChat({ curChat: this.curChat, oldCurId: this.curId });
    props.fatherRenderFunc({change:true});
  }
  //when you do not click the "add Chat" button, and you make a chat
  //this function will be executed
  addChat_CompleteChat(props){
    this.curChat = props.curChat;
    props.curChat.setCurChat({curChat: props.curChat, oldCurId: -4 });
    props.curChat.fatherRenderFunc({change:false});
  }
}
