import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // 引入bootstrap样式
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import "./css/App.css";
import ChatInterface from "./utils/ChatInterface"; // 引入 ChatInterface 组件
import LoginPage from "./utils/LoginPage"; // 引入 LoginPage 组件
import addChats from "./utils/addChats";
import fetchDialogs from "./utils/fetchDialogs";
import Logout from "./utils/logout"
import { API_URL } from './config/config';
import uuid from "./utils/uuid"
import AddChat from "./utils/addChat";
import handleRenderHistory from "./utils/handleRenderHistory";
import clearDialogs from "./utils/clearDialogs";
import FileUploadComponent from './utils/FileUploadComponent';
import FileListComponent from './utils/FileListComponent';
import { Switch } from 'antd';



var addChats_ = new addChats();
var initFlag = false;
var addNewChatRender = null;
function App() {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showFileListModal, setShowFileListModal] = useState(false);

  const handleOpenUploadModal = () => setShowUploadModal(true);
  const handleCloseUploadModal = () => setShowUploadModal(false);

  const handleOpenFileListModal = () => setShowFileListModal(true);
  const handleCloseFileListModal = () => setShowFileListModal(false);

  //This variable is used to check whether the rightSide has been expanded
  const [isLeftSideCollapsed, setIsLeftSideCollapsed] = useState(false);
  //This variable is used to fetch the history dialogs on the left list
  const [dialogs, setDialogs] = useState([]);
  //This variable is used to render the conversations on the right side
  const [messages, setMessages] = useState([]);


  const token = localStorage.getItem("token");

  //These lines are used to rerender the root page
  const [state, setState] = useState([]);

  const [showLeftSide, setShowLeftSide] = useState(false);

  const [shouldShowLeftSide, setShouldShowLeftSide] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 800) {
        setShowLeftSide(true);
      }
      if (window.innerWidth < 800) {
        setShowLeftSide(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  async function fatherRenderFuctionRoot(props) {
    await handleRenderHistory(addChats_.curChat == null ? -1 : addChats_.curChat.threadId, props.change);
    if (addNewChatRender != null) {
      addNewChatRender({ change: "father" });
    }
    setState([...state, 1]);

  }

  const handleLogoClick = () => {
    // 首先确保左侧栏是可见的
    setShowLeftSide(true);

    // 然后在下一个渲染周期改变状态，以触发过渡效果
    requestAnimationFrame(() => {
      setIsLeftSideCollapsed(!isLeftSideCollapsed);
    });
  };

  useEffect(() => {
    if (isLeftSideCollapsed) {
      // 如果左侧栏被收起，那么在过渡效果结束后隐藏它
      const timeoutId = setTimeout(() => {
        setShowLeftSide(false);
      }, 300); // 300毫秒的延迟应该与你的过渡效果的持续时间相匹配

      return () => clearTimeout(timeoutId); // 清理函数
    }
  }, [isLeftSideCollapsed]);

  useEffect(() => {
    // 这个useEffect看起来是用来处理当addChats_.curChat改变时的逻辑，不涉及左边栏的显示与隐藏
    setIsLeftSideCollapsed(true); // 似乎是初始化或者重置左边栏的状态
    fatherRenderFuctionRoot({ change: true });
  }, [addChats_.curChat]);


  function setAddNewChatRender(props) {
    addNewChatRender = props.addNewChatRender;
  }


  //This is the function that is used by other child components to update the father "addChats_"
  //And this function takes two steps: 
  // 1. set the "choose" filed of the old "cur" to false
  // 2. set the "curChat" filed of the addChats_ to the props.curChat
  //NOTE: this function may be used to other things which is just to simplify the design....
  //including the "deleteDialog"(the oldCurId=-2)、"fetchDialogs"(the oldCurId=-5) and others.... 
  function setCurChat(props) {

    if (props.oldCurId === -6) {
      // addChats_.init();
      const tmpId = uuid(8, 10);
      addChats_.NewChat = {};
      addChats_.curId = -3;    //the -3 represents to the null
      addChats_.curChat = new AddChat({
        choose: false,
        father: addChats_,
        threadId: -1,
        title: null,
        id: -3,
        fatherRenderFunc: fatherRenderFuctionRoot,
        setCurChat: setCurChat,
        handleRenderHistory: handleRenderHistory,
        persistId: tmpId
      });
      addChats_.NewChat[-3] = addChats_.curChat;
      addChats_.NewChat[-3].delete = true;
      return;
    }

    //This case happens when the "fetchDialogs" functioning
    //We need to use this function to set the "exclusiveStartKey" and "max_id" of the "addChats_"
    //Obviously, oldCurId is just a flag that used to execute these two lines
    //NOT THE REAL "oldCurId"
    if (props.oldCurId === -5) {

      addChats_.exclusiveStartKey = props.exclusiveStartKey;
      addChats_.max_id = props.max_id;
      return;
    }

    //Not Similarly to the above case, in this case, it takes the step 1 of the normal way
    //We should set the oldCur's "choose" field to false
    //And this will happend only when the "choosen cur" and the "old cur" are not the same
    //NOTE: the oldCurId may be set to -1 which represents the "new Chat" that doesn't make a chat to gpt
    //This NOTE refers to the first condition
    if (
      addChats_.NewChat[props.oldCurId] != undefined &&
      props.oldCurId !== -2 && //represents the "deleteDialog"
      props.oldCurId !== props.curChat.id  //the "choosen cur" and the "old cur" should not be the same
    ) {
      addChats_.NewChat[props.oldCurId].setChoose({ choose: false });
    }

    //This case happens when the "deleteDialog" functioning 
    //because the "oldCurId" has been set to -2
    if (props.oldCurId === -2) {
      //first is to set the "delete" feild of the chose delete curChat
      addChats_.NewChat[addChats_.curChat.id].delete = true;
      //This case happens when the left-list exists ~
      // the newChat that does not make a chat to gpt
      if (addChats_.NewChat[-1] != undefined && addChats_.NewChat[-1].delete == false) {
        addChats_.curChat = addChats_.NewChat[-1]; //set new chat to the "curChat"
        addChats_.curId = -1;    //set the curId to -1
        addChats_.curChat.setChoose({ choose: true });
      }
      //This case happens when the left-list doesn't exsit
      //the newChat that does not make a chat to gpt
      else {
        const tmpId = uuid(8, 10);
        addChats_.curId = -3;    //the -3 represents to the null
        addChats_.curChat = new AddChat({
          choose: false,
          father: addChats_,
          threadId: -1,
          title: null,
          id: -3,
          fatherRenderFunc: fatherRenderFuctionRoot,
          setCurChat: setCurChat,
          handleRenderHistory: handleRenderHistory,
          persistId: tmpId
        });
        addChats_.NewChat[-3] = addChats_.curChat;
        addChats_.NewChat[-3].delete = true;
      }
    }
    else {
      //This the step2 od the normal way of this function
      addChats_.NewChat[props.curChat.id] = props.curChat;
      addChats_.curChat = props.curChat;
      addChats_.curId = props.curChat.id;
      addChats_.NewChat[props.curChat.id].setChoose({ choose: true });
    }
  }



  //This is a function that is used to set the id and the title of~
  //the new chat that does not make a chat with the gpt
  //There are some steps that the function takes
  //1.delete the new Chat of -1 curId
  //2.set the -1 to the (new Date()).valueOf()()
  //2.update the curChat and the NewChat array
  async function setIdandTitle(id, title) {

    const oCurId = addChats_.curId;

    const tmp = addChats_.NewChat[addChats_.curId];//curId is -1
    delete addChats_.NewChat[addChats_.curId];

    addChats_.curChat.threadId = id;
    addChats_.curChat.title = title;
    const tmpId = uuid(8, 10);
    addChats_.curChat.id = tmpId; // "47473046";
    addChats_.curId = addChats_.curChat.id;

    addChats_.NewChat[addChats_.curId] = tmp;
    addChats_.NewChat[addChats_.curId].threadId = id;
    addChats_.NewChat[addChats_.curId].title = title;

    if (oCurId === -3) {
      addChats_.NewChat[addChats_.curId].delete = false;
      addChats_.NewChat[addChats_.curId].choose = true;
    }

  }

  async function handleLogout() {
    await Logout();
  }


  if (initFlag === false || addChats_.curChat === null) {
    const tmpId = uuid(8, 10);
    addChats_.curId = -3;    //the -3 represents to the null
    addChats_.curChat = new AddChat({
      choose: false,
      father: addChats_,
      threadId: -1,
      title: null,
      id: -3,
      fatherRenderFunc: fatherRenderFuctionRoot,
      setCurChat: setCurChat,
      handleRenderHistory: handleRenderHistory,
      persistId: tmpId
    });
    addChats_.NewChat[-3] = addChats_.curChat;
    addChats_.NewChat[-3].delete = true;
    initFlag = true;
  }

  const userPosition = localStorage.getItem("userPosition");
  const FileButtonShow = (userPosition === "预算员" || userPosition === "dev");




  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage setDialogs={setDialogs} />} />
        <Route
          path="/app"
          element={
            <div className="App">
              <div className="top-bar">

                <div className="newLogo" onClick={handleLogoClick}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z" fill="currentColor"></path></svg>
                </div>
                {/* <img src="logo.png"  className="company-logo" /> */}
                <div className="companyLogo">
                  <svg width="52" height="52" viewBox="0 0 52 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M51.5128 44.4784H51.3274C47.5426 35.329 39.9709 26.4358 33.5491 20.2573C36.4088 18.8008 39.4898 17.4666 42.6484 16.3336C48.9393 24.3057 52.1258 34.3482 51.5128 44.4784ZM0.729901 44.4784H0.607201V13.8384C5.6763 13.7472 10.7761 13.1227 15.8057 11.7098C20.0708 14.093 24.132 16.8161 27.8175 20.0781C16.2303 26.133 9.0822 34.6059 0.729901 44.4784ZM0.607201 11.161V4.56122C4.6572 6.23682 8.7037 8.00442 12.6195 10.0077C9.0588 10.6322 5.0598 11.0587 0.607201 11.161ZM31.4706 18.3131C27.9564 15.1205 24.059 12.5572 19.9284 10.4297C23.3846 9.22882 26.776 7.64412 30.054 5.59572C34.3503 8.00662 38.2573 11.1601 41.5432 14.991C38.1051 15.7237 34.7449 16.8605 31.4706 18.3131ZM17.1223 9.06562C11.8308 6.63492 6.2272 4.81962 0.607201 3.24422V0.76292C10.251 -1.08548 20.2294 0.47282 28.7968 4.91662C25.9003 6.32692 22.0282 7.86552 17.1223 9.06562Z" fill="black" />
                    <path d="M45.6639 44.4784H6.4292C13.622 36.1744 20.4287 27.7769 30.0518 22.1632C35.717 27.7051 42.6937 36.4077 45.6639 44.4784Z" fill="black" />
                  </svg>
                </div>
                <span className="company-name">广泽洽特阿呆</span>

                <div className="upload-link">



                  {
                    FileButtonShow && (<><Button onClick={handleOpenFileListModal} className="UButton">
                      <svg t="1715487675986" class="icon" viewBox="0 0 1000 1000" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4305" width="24" height="24"><path d="M302.3 292.032c-23.398 0-42.365 18.964-42.365 42.361s18.967 42.365 42.365 42.365 42.365-18.967 42.365-42.365-18.966-42.361-42.365-42.361zM302.3 630.933c-23.398 0-42.365 18.967-42.365 42.363 0 23.397 18.967 42.363 42.365 42.363s42.365-18.966 42.365-42.363c0-23.395-18.966-42.363-42.365-42.363zM302.3 461.482c-23.398 0-42.365 18.966-42.365 42.363s18.967 42.363 42.365 42.363 42.365-18.966 42.365-42.363-18.966-42.363-42.365-42.363zM725.948 630.933h-254.188c-23.398 0-42.365 18.967-42.365 42.363 0 23.397 18.967 42.363 42.365 42.363h254.188c23.398 0 42.365-18.966 42.365-42.363 0-23.395-18.967-42.363-42.365-42.363zM725.948 292.032h-254.188c-23.398 0-42.365 18.964-42.365 42.361s18.967 42.365 42.365 42.365h254.188c23.398 0 42.365-18.967 42.365-42.365s-18.967-42.361-42.365-42.361zM853.043 37.853h-677.837c-46.794 0-84.729 37.934-84.729 84.725v762.532c0 46.791 37.935 84.725 84.729 84.725h677.837c46.794 0 84.727-37.934 84.727-84.725v-762.532c0-46.791-37.933-84.725-84.727-84.725zM853.043 842.747c0 23.397-18.97 42.363-42.367 42.363h-593.105c-23.398 0-42.365-18.966-42.365-42.363v-677.806c0-23.395 18.967-42.363 42.365-42.363h593.105c23.398 0 42.367 18.969 42.367 42.363v677.806zM725.948 461.482h-254.188c-23.398 0-42.365 18.966-42.365 42.363s18.967 42.363 42.365 42.363h254.188c23.398 0 42.365-18.966 42.365-42.363s-18.967-42.363-42.365-42.363z" p-id="4306"></path></svg>
                      <span className="buttonText">文件列表</span></Button><Modal show={showFileListModal} onHide={handleCloseFileListModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>现有文件</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <FileListComponent /> {/* Replace this with your file list component */}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleCloseFileListModal}>关闭</Button>
                        </Modal.Footer>
                      </Modal><Button onClick={handleOpenUploadModal} className="UButton">
                        <svg t="1715487835644" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5332" width="24" height="24"><path d="M844.205101 211.183793 713.806215 211.183793l0 74.517157 74.513064 0c20.566408 0 37.258579 16.688077 37.258579 37.254485l0 409.834132c0 20.566408-16.692171 37.254485-37.258579 37.254485L266.717597 770.044054c-20.566408 0-37.259602-16.688077-37.259602-37.254485L229.457995 322.955436c0-20.566408 16.693194-37.254485 37.259602-37.254485l74.513064 0 0-74.517157-130.399909 0c-30.867008 0-55.885821 25.018813-55.885821 55.885821l0 521.605775c0 30.867008 25.018813 55.886845 55.885821 55.886845l633.374348 0c30.871101 0 55.890938-25.019837 55.890938-55.886845L900.096039 267.069615C900.096039 236.202607 875.076202 211.183793 844.205101 211.183793L844.205101 211.183793zM528.879436 159.693072 322.603419 365.964996l54.526871 54.527894 113.131617-113.131617L490.261906 653.350308c0 15.425318 12.519128 27.945469 27.944446 27.945469l18.627243 0c15.426341 0 27.941376-12.520151 27.941376-27.945469L564.774971 291.487747l129.340787 129.320321 52.683896-52.679803L547.490306 168.799474l-4.752234 4.752234L528.879436 159.693072 528.879436 159.693072z" fill="#272636" p-id="5333"></path></svg>
                        <span className="buttonText">上传文件</span></Button><Modal show={showUploadModal} onHide={handleCloseUploadModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>文件上传</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <FileUploadComponent />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleCloseUploadModal}>关闭</Button>
                        </Modal.Footer>
                      </Modal></>)
                  }
                </div>

                <div onClick={handleLogout} className="logout-container">
                  <img
                    src="logout.png"
                    className="user-avatar"
                  />
                  <div className="logout">
                    退出
                  </div>
                </div>
              </div>
              <div className="content" id="SumChat">
                <div
                  className={`left-side ${isLeftSideCollapsed ? "collapsed" : ""} ${showLeftSide ? "show" : "hide"}`}
                // onScrollCapture={() => onScrollHandle()}
                >
                  {/* Left side content goes here */}
                  {/* Replace with your chat history component */}
                  <AddNewChat
                    addChats={addChats_}
                    handleRenderHistory={handleRenderHistory}
                    setCurChat={setCurChat}
                    fatherRenderFuctionRoot={fatherRenderFuctionRoot}
                    setAddNewChatRender={setAddNewChatRender}
                  />
                </div>

                <div
                  className={`collapse-button ${isLeftSideCollapsed ? "collapsed" : ""}`}
                  onClick={() => {
                    if (window.innerWidth > 800) {
                      setIsLeftSideCollapsed(!isLeftSideCollapsed);
                    }
                  }}
                >
                  <div></div>
                  <div></div>
                </div>
                <div
                  className={`right-side ${isLeftSideCollapsed ? "expanded" : ""}`}
                >
                  {/* Pass isLeftSideCollapsed as a prop to ChatInterface */}
                  <ChatInterface
                    isCollapsed={isLeftSideCollapsed}
                    addChats_={addChats_}
                    setIdandTitle={setIdandTitle}
                    convers={messages}
                    setCurChat={setCurChat}
                    fatherRenderFunc={fatherRenderFuctionRoot}
                    handleRenderHistory={handleRenderHistory}

                  />
                </div>
              </div>

            </div>
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

function AddNewChat(props) {
  var addChats = props.addChats;
  let fatherRender = props.fatherRenderFuctionRoot;

  async function fatherRenderFunc(props) {
    setState([...state, 1]);
    if (props.change !== "father")//避免死递归
      await fatherRender({ change: props.change });
    // else
    // await fatherRender({change: props.change});
  }

  let setAddNewChatRender = props.setAddNewChatRender;
  setAddNewChatRender({ addNewChatRender: fatherRenderFunc });

  //this is used to fetch dialogs for the first time the page loaded
  if (addChats.NewChat["getHistory"] === "no") {
    addChats.NewChat["getHistory"] = "yes";
    fetchDialogs({
      addChats: addChats,
      setCurChat: props.setCurChat,
      fatherRenderFunc: fatherRenderFunc,
      handleRenderHistory: props.handleRenderHistory,
    });
  }

  useEffect(() => { fatherRenderFunc({ change: true }) }, [props.addChats.NewChat]);

  const [len, setLen] = useState(1000);
  const [state, setState] = useState([]);

  // const [choose, setChoose] = useState(true);
  //The leftside-list will be sorted by the time they were created
  const sortedKeys = Object.keys(addChats.NewChat).filter(item => item !== 'getHistory').sort();
  const sortedValues = sortedKeys.map((key) => addChats.NewChat[key]);

  function isScrollbarAtBottom(props) {
    const element = props.element;
    return element.scrollHeight - element.scrollTop < (element.clientHeight + 0.4);
  }

  async function onScrollHandle(event) {
    if (isScrollbarAtBottom({ element: event.target }) && !(addChats.exclusiveStartKey === null)) {
      await fetchDialogs({
        addChats: addChats,
        setCurChat: props.setCurChat,
        fatherRenderFunc: fatherRender,
        handleRenderHistory: props.handleRenderHistory,
      });
      fatherRender({ change: false });
    }
  }

  var x = sortedValues.map((item, index) => {
    if (item.delete === false) {
      return item.render();
    }
  });

  return (
    <div className="left-side-container">
      <div className="add">
        <button
          className="button_add"
          onClick={() => {
            if (addChats.curChat === null || addChats.curChat.threadId != -1 || addChats.curChat.id === -3) {
              addChats.addChat({
                father: props.addChats,
                setCurChat: props.setCurChat,
                fatherRenderFunc: fatherRenderFunc,
                handleRenderHistory: props.handleRenderHistory,
                choose: true,
                id: -1,
                threadId: -1,
                title: null,
              });
              setLen(len + 1);
            }
          }}
        >
          <div className="new_chat">
            <span>新建对话</span>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z" fill="currentColor"></path>
            </svg>
          </div>
        </button>
      </div>
      <div id="AddNewChat" onScrollCapture={onScrollHandle}>{x}</div>
      <div className="clear">
        <button
          className="button_clear"
          onClick={async () => {
            clearDialogs();
            // addChats.NewChat = [];
            // addChats.init();
            props.setCurChat({ oldCurId: -6 });
            setLen(0);
            await fatherRender({ change: true });
          }}
        >
          <div className="delete_all">
            <span>清空对话</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              className="bin"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}

export default App;
