import Placeholder from "./placeHolder";
import ReactMarkdown from "react-markdown";
export default function Conversations(
  conversations,
  selectUrl,
  handleOnSelect,
  copyToClipboard,
  history_x,
  showText
) {
  const portrait = localStorage.getItem("portrait");
  return conversations.map((conv, index) => (
    <div key={index} className={`message ${conv.role}`}>
      {conv.role === "user" && (
        <div className="response-message">
          <img src={portrait} className="avatar" />
          <span className="spanSum">
            <strong>
              你<br />
            </strong>
            {!/\.(wav|mp3)$/i.test(conv.content) &&
            !conv.content.startsWith("data:audio/wav") ? (
              <span className="contentSpanUser">
                <ReactMarkdown>{conv.content}</ReactMarkdown>
              </span>
            ) : (
              <div onClick={() => handleOnSelect(conv.content)}>
                <Placeholder
                  Url={conv.content}
                  selectUrl={selectUrl}
                  color1="rgb(29,155,240)"
                />
              </div>
            )}
          </span>
        </div>
      )}
      {conv.role === "assistant" && (
        <div className="response-message">
          <img src="chat.png" className="avatar" />
          <span className="spanSum">
            <strong>
              洽特阿呆
              <br />
            </strong>
            <>
              {!/\.(wav|mp3)$/i.test(conv.content) &&
              !conv.content.startsWith("blob:http") ? (
                <span>
                  <ReactMarkdown>{conv.content}</ReactMarkdown>
                </span>
              ) : (
                conv.content && (
                  <div onClick={() => handleOnSelect(conv.content)}>
                    <Placeholder
                      Url={conv.content}
                      selectUrl={selectUrl}
                      color1="#43A047"
                    />
                  </div>
                )
              )}
            </>
          </span>
          <p>
            {!/\.(wav|mp3)$/i.test(conv.content) && showText && (
              <button
                className="copy"
                title=""
                id={"copyButton" + index + history_x.length}
                onClick={() => {
                  copyToClipboard({
                    content: conv.content,
                    id: "copyButton" + index + history_x.length,
                  });
                }}
              >
                <svg
                  t="1713194616762"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  id="svgImg"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="4910"
                  width="16"
                  height="16"
                >
                  <path
                    d="M661.333333 234.666667A64 64 0 0 1 725.333333 298.666667v597.333333a64 64 0 0 1-64 64h-469.333333A64 64 0 0 1 128 896V298.666667a64 64 0 0 1 64-64z m-21.333333 85.333333H213.333333v554.666667h426.666667v-554.666667z m191.829333-256a64 64 0 0 1 63.744 57.856l0.256 6.144v575.701333a42.666667 42.666667 0 0 1-85.034666 4.992l-0.298667-4.992V149.333333H384a42.666667 42.666667 0 0 1-42.368-37.674666L341.333333 106.666667a42.666667 42.666667 0 0 1 37.674667-42.368L384 64h447.829333z"
                    fill="#000000"
                    p-id="4911"
                  ></path>
                </svg>
              </button>
            )}
          </p>
        </div>
      )}
    </div>
  ));
}
