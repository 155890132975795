
import { API_URL } from "../config/config";

//This is the function that we use it to render the history conversations
export default async function handleRenderHistory(props) {
    const id = props.id;
    const change = props.change;
    //These are some ids that maybe passed into this function
    //Therefore, just set the conversations to the "[]"
    if(id === -1 || id === -2 || id === -3 || id === -4)
    {
    //   setMessages([]);
      return [];
    }
    if (id === null || id === undefined) {
    //   setMessages([]);
      return [];
    }
    if (change === false) {
    //   setMessages([]);
      return [];
    }
    try {
    
    const token = localStorage.getItem("token");

    const queryParams = new URLSearchParams({
      threadId: id
    });
      //We use the url "/chatbot/render" to get the newest conversations
      const response = await fetch(
        `${API_URL}/chatbot/getMessages?${queryParams}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}` 
          },
        },
      );
      //This happens when the token expires or the user did not login
      //We use 400 to state this situation
      if(response.status === 400) {
        alert("非法用户")
        window.location.href = "/login";
        console.error("请求失败:", response.statusText);
        return [];
      }
      //This situation should be normal. 
      //We get the data and store it into the messages
      else {
        let data = await response.json();
        data = data.sort((a, b) => a.time - b.time);
        // setMessages(data); //We get the data and store it into the messages
        return data;
      } 
    } catch (error) {
    //   setMessages([])
      console.error("请求错误:", error);
      return [];
    }
  };