import React, { useState, useEffect, useRef } from "react";
import "../css/ChatInterface.css";
import AddChat from "./addChat";
import { API_URL } from "../config/config";
import uuid from "./uuid";
import handleRenderHistory from "./handleRenderHistory";
import TextareaAutosize from "react-textarea-autosize";
import ReactMarkdown from "react-markdown";
import FileUploadButton from "./FileUploadButton";
import { Switch } from "antd";
import Recorder from "js-audio-recorder";
import Placeholder from "./placeHolder";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { check, request, PERMISSIONS, RESULTS } from "react-native-permissions";
import checkCharsNums from "./checkCharsNums";
import create_thread from "./create_thread";
import { handleMouseUp,handleMouseDown } from "./handleVoice";
import HistoryConversations from "./HistoryConversations";
import Conversations from "./Conversations";
 import { max_char } from "../config/config";

//注意这些变量只有2个状态


var response_flag = 0;//检测文字回复是否完成 
var respVoice = 1;//检测语音回复是否完成标志麦克风是否可以使用 1:可以使用
var statusPage = "";//检测是否换页 空字符串时为换页
 
const ChatInterface = ({
  isCollapsed,
  addChats_,
  setIdandTitle,
  convers,
  setCurChat,
  fatherRenderFunc,
  handleRenderHistory,
}) => {
  var curChat = addChats_.curChat;
  var delteteNew = 0; //delete->New

  if (curChat != null && curChat.id == -3) {
    delteteNew = 1; //delete->New
  }

  var status = 0;
  const [message, setMessage] = useState("");//输入框中消息
  const [historyConversations, setHistoryConversations] = useState(convers);//历史发送对话
  const [conversations, setConversations] = useState([]);//新发送的对话
  const [st, setSt] = useState([]);
  const [selectUrl, setSelectUrl] = useState("");//设置选择播放的url，其余url停止播放
  const [respVoice,setRespVoice] = useState(1);
  // const [statusPage,setStatusPage] = useState("");

  const token = localStorage.getItem("token");

  const scrollToBottom = () => {
    const chatContainer = document.getElementById("test");
    chatContainer.scrollTop = test.scrollHeight;
  };

  // useEffect(() => {
  //   dwn_flag = 0;
  //   permit_flag = 0;
  //   deny_flag = 0;
  // }, []);

  useEffect(() => {
    setHistoryConversations([]);
  }, [curChat != null]);

  useEffect(() => {
    const fetchData = async () => {
      if (curChat !== null) {
        const newConvers = await handleRenderHistory({
          id: curChat.threadId,
          change: true,
        });
        setHistoryConversations(newConvers);
      }
    };
    fetchData();
  }, [curChat != null && curChat.persistId]);

  useEffect(() => {
    setConversations([]);
    setSelectUrl("");
    if (response_flag == 1) statusPage = "pageChanged";
  }, [curChat != null && curChat.persistId]);

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  const portrait = localStorage.getItem("portrait");

  //该函数控制的变量数量太多，未分离
  const sendMessage = async (message, file) => {
    var slicedStr;
    if (showText) {
      if (message.trim() === "") {
        return; // If the message is empty or contains only whitespace, take no action
      }
      const byteLength = checkCharsNums({'message':message});
      if (byteLength > max_char) {
        alert("字数太多无法发送!最多不能超过3995个字符");
        return;
      }
      slicedStr = message.slice(0, Math.min(10, message.length));
      if (message.length > 10) {
        slicedStr = slicedStr + "...";
      }
    }
  
    if (curChat != null) {
      //Normally, the curChat will not be null
      //New Chat but no making a chat with gpt
      if (curChat.threadId === -1) { 
        const response = await create_thread({'slicedStr':slicedStr});
        //This happens when the token expires or the user did not login
        //We use 400 to state this situation
        if (response.status === 400) {
          window.location.href = "/login";
          return null;
        } else {
          //Set the threadId and the title into the NewChat
          const responseData = await response.json();
          //deleteNew: the info has been added into the curChat
          //in the next line, these info will be added into the addChats_ together
          if (showText) await setIdandTitle(responseData.threadId, slicedStr);
          else await setIdandTitle(responseData.threadId, "语音功能");
        }
      }
    }

    setConversations((conversations) => {
      return [
        ...conversations,
        { content: message, role: "user" },
        { content: "", role: "assistant" },
      ];
    });//为了及时将消息渲染上去

    setMessage("");//设置输入框消息为空

    try {
      //if之前的情况是发送create_massge请求  
      //并且默认情况是showText情况 default: showText case
      //showText是发送文字 // showText-> send Text to gpt
      const userId = localStorage.getItem("userId");
      const realName = localStorage.getItem("realName");
      //We use the url "/chatbot/create_message" to create the stream of the gpt response
      var apiUrl = `${API_URL}/chatbot/create_message`;
      var param = JSON.stringify({
        message: message,
        threadId: curChat.threadId,
        assistantId: "asst_l5NTTbMmeqlFVJNKqIGDohYP",
        userId: userId,
        realName: realName,
      });
      var header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      if (!showText) {
        //这种情况是发送语音(!showText)
        //需要重新设置请求链接、请求参数、请求头等等
        apiUrl = `${API_URL}/audio/audio_to_audio`;
        // 创建一个新的 FormData 对象
        const formData = new FormData(); 
        // 添加文件对象到 FormData
        formData.append("file", file);
        formData.append("threadId", curChat.threadId);
        formData.append("assistantId", "asst_l5NTTbMmeqlFVJNKqIGDohYP");
        formData.append("userId", userId);
        formData.append("realName", realName);
        param = formData;
        header = {
          Authorization: `Bearer ${token}`,
        };
      }
      //进行上述设置完成之后开始发送请求
      const response = await fetch(
        apiUrl,
        {
          method: "POST",
          headers: header,
          body: param,
        },
        { mode: "no-cors" }
      );
      //This happens when the token expires or the user did not login
      //We use 400 to state this situation
      // respVoice = 1;
      setRespVoice(1);
      if (response.status === 400) {
        window.location.href = "/login";
        return null;
      } else if (response.status === 401) {
        //这种情况是录音文件没有加入到参数中
        alert("文件未加入");
        return null;
      } else {
        if (!showText) {
          //在这里比较复杂，因为本地有的时候录音可能会出问题
          //一般是先把本地的录音发上去，然后后端传回用户和gpt发的语音链接
          //用s3的链接覆盖掉本地的录音链接
          const respData = await response.json();
          const assistantUrl = respData.assistantUrl; //gpt语音链接
          const userUrl = respData.userUrl;//用户语音链接

          setConversations((convs) => {
            const lastConv = convs[convs.length - 1];
            const lastConvUser = convs[convs.length - 2];
            if (lastConv && lastConv.role === "assistant") {
              // If the last message is a response, append the new data chunk to it
              // Create a new object instead of directly modifying lastConv
              const newLastConv = {
                ...lastConv,
                content: assistantUrl,
              };
              const newLastConvUser = {
                ...lastConvUser,
                content: userUrl,
              };//在这里是使用覆盖
              // Replace the last message with the new object
              return [...convs.slice(0, -2), newLastConvUser, newLastConv];
            } else {
              // Otherwise, create a new message
              return [
                ...convs.slice(0, -1),
                { content: userUrl, role: "user" },
                { content: assistantUrl, role: "assistant" },
              ];
            }
          });
        } else {
          //  use the stream reader to get the data
          const reader = response.body.getReader();
          let decoder = new TextDecoder();
          reader.read().then(function processText({ done, value }) {
            if (done) {
              if (statusPage === "pageChanged") {
                //  setStatusPage("");
                statusPage = "";
              }
              response_flag = 0;
              setSt([...st, 1]);
              return;
            }
            response_flag = 1;
            // Convert data chunks in the stream to text
            let chunk = decoder.decode(value, { stream: true }); 
            if (statusPage !== "pageChanged") {
              setConversations((convs) => {
                const lastConv = convs[convs.length - 1];
                if (lastConv && lastConv.role === "assistant") {
                  // If the last message is a response, append the new data chunk to it
                  // Create a new object instead of directly modifying lastConv
                  const newLastConv = {
                    ...lastConv,
                    content: lastConv.content + chunk,
                  };
                  // Replace the last message with the new object
                  return [...convs.slice(0, -1), newLastConv];
                } else {
                  // Otherwise, create a new message
                  return [...convs, { content: chunk, role: "assistant" }];
                }
              });
            }
            // read the new data block
            reader.read().then(processText);
          });
        }
      }
    } catch (error) {
      setConversations((convs) => [
        ...convs.slice(0, -1),//需要把gpt发送的空消息给顶下去
        { content: "Failed to get response", role: "assistant" },
      ]);
    } finally {
      if (status == 0) {
        curChat.fatherRenderFunc({ change: false });
        status = 1;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior, i.e., not inserting a newline character when Enter is pressed
      if (response_flag === 0) {
        sendMessage(message, 11);
      }
    }
    // If Shift and Enter are pressed simultaneously, allow the default behavior to occur, i.e., inserting a newline character
  };

  const chatInterfaceStyle = {
    left: isCollapsed ? "15%" : "24.5%",
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);

    const lines = e.target.value.split("\n").length;
    if (lines > 2 && lines <= 7) {
      e.target.rows = lines;
    } else if (lines > 7) {
      e.target.rows = 7;
    } else {
      e.target.rows = 2;
    }
  };

  const copyToClipboard = async (props) => {
    try {
      const content = props.content;
      const id = props.id;
      // const id = "oo";

      await navigator.clipboard.writeText(content);

      var svg = document.getElementById(id);

      var newSvg =
        '<svg t="1713237592009" viewBox="0 0 1024 1024" version="1.1" width="16" height="16" xmlns="http://www.w3.org/2000/svg" p-id="4405"><path d="M63.223 512s231.768 189.585 254.79 350.823c0 0 303.907-497.491 641.582-542.003 0 0-102.837-74.944-69.07-193.396 0 0-187.256 18.685-540.279 566.638l-165.452-280.85L63.223 512z" p-id="4406"></path></svg>';

      svg.innerHTML = newSvg;

      setTimeout(function () {
        var originSvg =
          '<svg t="1713194616762" viewBox="0 0 1024 1024" version="1.1" width="16" height="16" xmlns="http://www.w3.org/2000/svg" p-id="4910"><path d="M661.333333 234.666667A64 64 0 0 1 725.333333 298.666667v597.333333a64 64 0 0 1-64 64h-469.333333A64 64 0 0 1 128 896V298.666667a64 64 0 0 1 64-64z m-21.333333 85.333333H213.333333v554.666667h426.666667v-554.666667z m191.829333-256a64 64 0 0 1 63.744 57.856l0.256 6.144v575.701333a42.666667 42.666667 0 0 1-85.034666 4.992l-0.298667-4.992V149.333333H384a42.666667 42.666667 0 0 1-42.368-37.674666L341.333333 106.666667a42.666667 42.666667 0 0 1 37.674667-42.368L384 64h447.829333z" fill="#000000" p-id="4911"></path></svg>';

        svg.innerHTML = originSvg;
      }, 2500);

      // alert('文本已复制到剪贴板！');
    } catch (error) {
      console.error("复制失败:", error);
    }
  };

  const handleOnSelect = (content) => {
    setSelectUrl(content);
  };
  const [showText, setShowText] = useState(true);
  useEffect(() => {
    setMessage("");
  }, [showText]);

  var history_x = HistoryConversations(historyConversations,selectUrl,handleOnSelect,copyToClipboard,showText);

  var x = Conversations(
    conversations,
    selectUrl,
    handleOnSelect,
    copyToClipboard,
    history_x,
    showText
  );

  const intervalRef = useRef(null);//用于进度条伸展
  const [width, setWidth] = useState(0);

  const timeoutRef = useRef(null);//用于延时5ms录音

 
  const handleOnSwitch = () => {
    setShowText(!showText);
  };

  const [eventHandlers, setEventHandlers] = useState({});


  useEffect(() => {
    if (!showText && respVoice == 1)
      setEventHandlers({
        onMouseDown: (e) => {
          handleMouseDown(e, setWidth, timeoutRef, intervalRef);
        },
        onMouseUp: (e) => {
          handleMouseUp(e, setWidth, timeoutRef, intervalRef, sendMessage,setRespVoice);
        },
        onTouchStart: (e) => {
          handleMouseDown(e, setWidth, timeoutRef, intervalRef);
        },
        onTouchEnd: (e) => {
          handleMouseUp(
            e,
            setWidth,
            timeoutRef,
            intervalRef,
            sendMessage,
            setRespVoice
          );
        },
      });
    else {
      setEventHandlers({});
    }
  }, [showText]);

  return (
    <div className="chat-container">
      <div className="messages" id="test">
        <div>{history_x}</div>
        <div>{x}</div>
      </div>

      <div className="input-container1">
        {showText ? (
          <svg
            onClick={handleOnSwitch}
            t="1718375451282"
            class="showText-switch"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4283"
            width="20"
            height="20"
          >
            <path
              d="M260.256 356.576l204.288-163.968a32 32 0 0 1 52.032 24.96v610.432a32 32 0 0 1-51.968 24.992l-209.92-167.552H96a32 32 0 0 1-32-32v-264.864a32 32 0 0 1 32-32h164.256zM670.784 720.128a32 32 0 0 1-44.832-45.664 214.08 214.08 0 0 0 64.32-153.312 213.92 213.92 0 0 0-55.776-144.448 32 32 0 1 1 47.36-43.04 277.92 277.92 0 0 1 72.416 187.488 278.08 278.08 0 0 1-83.488 198.976zM822.912 858.88a32 32 0 1 1-45.888-44.608A419.008 419.008 0 0 0 896 521.152c0-108.704-41.376-210.848-114.432-288.384a32 32 0 0 1 46.592-43.872c84.16 89.28 131.84 207.04 131.84 332.256 0 127.84-49.76 247.904-137.088 337.728z"
              fill="#000000"
              p-id="4284"
            ></path>
          </svg>
        ) : (
          <svg
            t="1718439436911"
            onClick={handleOnSwitch}
            class="showText-switch"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="10909"
            width="40"
            height="40"
          >
            <path
              d="M800 544h-96a32 32 0 0 1 0-64h64v-128a32 32 0 0 1 64 0v160a32 32 0 0 1-32 32zM128 192h768a64 64 0 0 1 64 64v512a64 64 0 0 1-64 64H128a64 64 0 0 1-64-64V256a64 64 0 0 1 64-64z m32 64a32 32 0 0 0-32 32v448a32 32 0 0 0 32 32h704a32 32 0 0 0 32-32V288a32 32 0 0 0-32-32H160z m64 64h64a32 32 0 1 1 0 64H224a32 32 0 1 1 0-64z m192 0h64a32 32 0 0 1 0 64h-64a32 32 0 0 1 0-64z m192 0h64a32 32 0 0 1 0 64h-64a32 32 0 0 1 0-64zM224 480h32a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z m160 0h32a32 32 0 0 1 0 64h-32a32 32 0 0 1 0-64z m160 0h32a32 32 0 0 1 0 64h-32a32 32 0 0 1 0-64zM224 640h32a32 32 0 0 1 0 64H224a32 32 0 0 1 0-64z m160 0h256a32 32 0 0 1 0 64h-256a32 32 0 0 1 0-64z m384 0h32a32 32 0 0 1 0 64h-32a32 32 0 0 1 0-64z"
              fill="#333333"
              p-id="10910"
            ></path>
          </svg>
        )}

        {showText && (
          <FileUploadButton curChat={curChat} setIdandTitle={setIdandTitle} />
        )}
        {!showText && respVoice == 1 && (
          <div className="top" {...eventHandlers}></div>
        )}
        <TextareaAutosize
          minRows={1}
          maxRows={7}
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={showText ? "请输入关键词..." : ""}
          className="message-input"
          readOnly={!showText}
          disabled={respVoice == 0}
        />
        {!showText && <span className="timer-input">{width / 10}s</span>}
        <span
          className="interval-input"
          style={{
            width: `${width}px`,
            height: "56px",
            transitionDuration: "0.8s",
            backgroundColor: "rgb(29,155,240)",
            borderRadius: "25px",
          }}
          ref={intervalRef}
        ></span>
        {showText ? (
          <button
            onClick={() => sendMessage(message, 11)}
            className="send-button"
            disabled={response_flag === 1}
            style={{ background: "none", border: "none", padding: 0 }}
          >
            <img
              src={
                message.trim() && response_flag === 0
                  ? "send-active-icon.png"
                  : "send-icon.png"
              }
              alt="Send"
            />
          </button>
        ) : (
          <svg
            t="1718591030592"
            class="voice-icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="12120"
            width="25"
            height="25"
          >
            <path
              d="M469.632 808.064A341.376 341.376 0 0 1 170.666667 469.333333h85.333333a256 256 0 0 0 256 256h1.749333A256 256 0 0 0 768 469.333333h85.333333a341.376 341.376 0 0 1-298.368 338.645334l0.426667 130.56-85.333333 0.256-0.426667-130.730667zM512 128a85.333333 85.333333 0 0 0-85.333333 85.333333v256a85.333333 85.333333 0 1 0 170.666666 0V213.333333a85.333333 85.333333 0 0 0-85.333333-85.333333z m0-85.333333a170.666667 170.666667 0 0 1 170.666667 170.666666v256a170.666667 170.666667 0 1 1-341.333334 0V213.333333a170.666667 170.666667 0 0 1 170.666667-170.666666z"
              fill="#2E2F30"
              p-id="12121"
            ></path>
            <path
              d="M341.333333 981.333333v-85.333333h341.333334v85.333333z"
              fill="#2E2F30"
              p-id="12122"
            ></path>
          </svg>
        )}
      </div>
      <ToastContainer
        position="top-center" // 或 "bottom-center"，根据你的需求选择
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false} // 添加这一行来去除关闭按钮
        style={{
          position: "fixed", // 确保ToastContainer不会随着页面滚动
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", // 同时垂直和水平居中
          zIndex: 9999, // 确保它位于大多数其他元素之上
        }}
      />
    </div>
  );
};

export default ChatInterface;
