import React, { useState, useEffect } from 'react';
import { API_URL } from '../config/config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';

function FileListComponent() {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(`${API_URL}/files/list_files`,  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": `Bearer ${token}` 
                    }
                  },);
                if (response.ok) {
                    let fileList = await response.json();
                    fileList = fileList.map(file => ({
                        ...file,
                        name: file.name.replace('uploads/', '')
                    }));
                    setFiles(fileList);
                } else {
                    throw new Error('Failed to fetch files');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        
        fetchFiles();
    }, []);

    const handleDelete = async (fileName) => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${API_URL}/files/delete_file?fileName=${encodeURIComponent(fileName)}`,
             { method: 'DELETE' ,
             headers: {
               "Content-Type": "application/json",
               "Authorization": `Bearer ${token}` 
             }});
            if (response.ok) {
                setFiles(files.filter(file => file.name !== fileName));
                alert('File deleted successfully');
            } else {
                throw new Error('Failed to delete file');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    const getFileIcon = (fileName) => {
        if (fileName.endsWith('.pdf')) {
            return <PictureAsPdfIcon color="error" />;
        } else if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
            return <ImageIcon color="success" />;
        } else {
            return <InsertDriveFileIcon />;
        }
    };
    

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                         <TableCell>序号</TableCell> {/* 新添加的序号列 */}
                        <TableCell>文件名</TableCell>
                        <TableCell>大小</TableCell>
                        <TableCell>上一次修改</TableCell>
                        <TableCell>操作</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {files.map((file, index) =>(
                        <TableRow key={file.name}>
                            <TableCell>{index + 1}</TableCell> 
                            <TableCell>{getFileIcon(file.name)} {file.name}</TableCell>
                            <TableCell>{file.size}Bytes</TableCell>
                            <TableCell>{new Date(file.lastModified).toLocaleString()}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleDelete(file.name)} aria-label="delete">
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default FileListComponent;
