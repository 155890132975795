import "../css/placeHolder.css";
import { useState,useEffect,useRef } from "react";

var audioEle = null;

function Placeholder({Url,color1,selectUrl}) {

    const [url,setUrl] = useState(Url);
    const [play,setPlay] = useState(true);
    const [str,setStr] = useState("");
    const flowRef = useRef(null);
    const [dur,setDur] = useState(0);
    const [audioEle,setAudioEle] = useState(() => new Audio(Url));
    // useEffect(()=>{
    //     setUrl(Url);
    // },[Url])
    // useEffect(()=>{
    //     console.log("setAudioEle")
    //     setAudioEle(new Audio(Url));
    // },[Url]);

    // console.log("PlaceHolder",Url);

    useEffect(()=>{
        if(selectUrl !== url && !play){
            if(audioEle)
                audioEle.pause();
            flowRef.current.style.display = 'none';
            setPlay(true);
        }

    },[selectUrl]);

    const handleOnClick = ()=>{
        // alert(url);
        
        if(play)
         {
            if(audioEle)
             { 
                audioEle.load();
                setTimeout(function() {
                audioEle.play();
                flowRef.current.style.display = 'flex';
                setPlay(!play);
                }, 0);
                
             }   
            else
                alert("音频链接还没有生成请稍候!")
            
         }   
        else
          {
            if(audioEle)
            {
                audioEle.pause();
                flowRef.current.style.display = 'none';
                setPlay(!play);
            }
            else
            alert("音频链接还没有生成请稍候!")
          }  
        
    }
    
    useEffect(()=>{
        // console.log("????")
        if(audioEle){
            // console.log(audioEle)
             const func = ()=>{
                 var duration = audioEle.duration;
                setDur(duration);
                // 把总时长显示出来，单位换成分钟和秒
                var minutes = Math.floor(duration / 60);
                var seconds = Math.floor(duration % 60)+1;
                if(minutes === 0)
                {
                    const x =  `${seconds}''`;
                    // console.log("duration",duration);
                    setStr(x);
                } 
                else{
                    const x =  `${minutes}'${seconds}''`;
                    // console.log("duration",duration);
                    setStr(x);
                }
            }
             audioEle.addEventListener('loadedmetadata',func)
             return ()=>{
                audioEle.removeEventListener('loadedmetadata',func);
             }
        }
       
       
    },[audioEle,url])
 
    const handleEnded = ()=>{
        if(flowRef && flowRef.current)
       {
        flowRef.current.style.display = 'none';
        setPlay(true);
        // console.log("hahahahend");
       } 
    }

    audioEle.addEventListener('ended', handleEnded);


    return (
        <div className="customAudio" onClick={handleOnClick} style={{'backgroundColor':`${color1}`}} >
            {
                play?
                <svg t="1718257264826"  class="audio-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10509" width="28" height="28"><path d="M501.9648 73.216a428.7488 428.7488 0 1 1 0 857.4976 428.7488 428.7488 0 0 1 0-857.4976z m0 62.72a366.0288 366.0288 0 1 0 0 732.0576 366.0288 366.0288 0 0 0 0-732.0576z m-46.08 167.2704c17.8176 0 35.2256 5.0176 50.176 14.4896l6.2976 4.3008 133.9392 100.4544a94.1056 94.1056 0 0 1 5.2224 146.432l-5.2224 4.1472-133.9392 100.4032a94.1056 94.1056 0 0 1-150.272-67.6864l-0.3072-7.5776V397.312c0-51.968 42.1376-94.1056 94.1056-94.1056z m0 62.72c-15.872 0-28.9792 11.776-31.0784 27.136l-0.256 4.2496v200.8576a31.3856 31.3856 0 0 0 46.2336 27.5968l3.9424-2.56 133.9392-100.352 3.3792-2.9184 2.9184-3.3792 2.5088-3.9424a31.3856 31.3856 0 0 0-5.3248-36.9152l-3.4816-3.072L474.7264 372.224a31.3856 31.3856 0 0 0-18.8416-6.2976z" fill="#FFFFFF" p-id="10510"></path></svg>
                :
                <svg t="1718257218221"  class="audio-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9474" width="28" height="28"><path d="M512 74.6496a437.3504 437.3504 0 1 1 0 874.7008 437.3504 437.3504 0 0 1 0-874.7008z m0 64a373.3504 373.3504 0 1 0 0 746.7008 373.3504 373.3504 0 0 0 0-746.7008z m74.6496 202.7008c17.7152 0 32 14.336 32 32v277.2992a32 32 0 1 1-64 0V373.3504c0-17.7152 14.336-32 32-32z m-148.6336-0.1024c17.664 0 32 14.336 32 32v277.3504a32 32 0 1 1-64 0V373.248c0-17.664 14.336-32 32-32z" fill="#FFFFFF" p-id="9475"></path></svg>
            }
             
            <div class="dajianshi" ref={flowRef}>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		    </div>
            <span className="ptime">{str}</span>
        </div>
    )
}

export default Placeholder
