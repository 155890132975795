
import { toast, ToastContainer } from "react-toastify";
import Recorder from "js-audio-recorder";
import {
  isIOS,
  isAndroid,
  isChrome,
  isIE,
  BrowserView,
  MobileView,
} from "react-device-detect";
import { judgeIosPermission, requestAndroidPermission } from "./permission";
import { PermissionsAndroid } from "react-native";


var cur_width = 0;//width变化不及时设置此项，width用于标记进度条宽度
var permit_flag = 0;//是否初始申请权限 0:不申请
var deny_flag = 0;//是否权限拒绝 0:为拒绝
var recorder = new Recorder({
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
  sampleRate: 24000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  numChannels: 1, // 声道，支持 1 或 2， 默认是1
  // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
});
var flag = 0;//是否重复up，主要解决进度条最后松开的情况
var dwn_flag = 0;//检测按钮是否按下 0:未按下

export const handleMouseUp = (
  event,
  setWidth,
  timeoutRef,
  intervalRef,
  sendMessage,
  setRespVoice
) => {
  event.preventDefault();
  // alert("识别抬起");

  if (intervalRef.current) {
    //清除进度条伸展
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }
  if (flag === 1) {
    //超时已经执行过一次了，这里要在执行一次
    flag = 0;
    return;
  }
  if (dwn_flag === 0) return;

  if (timeoutRef.current) {
    //如果在5ms内说明误触
    setWidth(0);
    cur_width = 0; //进度条长度清0
    clearTimeout(timeoutRef.current); //去掉5ms延时
    timeoutRef.current = null;
    if (intervalRef.current) {
      //去掉5ms伸展
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    if (permit_flag == 0 && deny_flag == 0) {
      //权限禁止或者初始申请权限
      toast.info("说话时间太短!", {
        position: "bottom-center",
      });
    }
    return;
  }
  if (cur_width < 10) {
    //按下时间太短
    setWidth(0);
    cur_width = 0; //进度条长度清0
    clearTimeout(timeoutRef.current); //去掉5ms延时
    timeoutRef.current = null;
    if (intervalRef.current) {
      //去掉5ms伸展
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    recorder.pause();
    recorder.stopPlay(); //需要让录音机停止录音
    if (permit_flag == 0 && deny_flag == 0) {
      //权限禁止或者初始申请权限
      toast.info("说话时间太短!", {
        position: "bottom-center",
      });
    }
    return;
  }
  dwn_flag = 0; //清除状态
//   resp_voice = 0;
  setRespVoice(0); //设置回复状态

  setWidth(0);
  cur_width = 0;
  clearTimeout(timeoutRef.current);
  timeoutRef.current = null; //作用同上
  recorder.pause(); // 暂停录音
  const formData = new FormData();
  const blob = recorder.getWAVBlob(); // 获取wav格式音频数据
  // 创建一个 FileReader 来读取 Blob 数据并生成 data URL
  const reader = new FileReader();
  //下面是生成录音文件以及链接的代码
  reader.onloadend = function () {
    const dataUrl = reader.result;
    recorder.stopPlay();
    // 输出生成的 data URL
    const newbolb = new Blob([blob], { type: "audio/wav" });
    const fileOfBlob = new File([newbolb], new Date().getTime() + ".wav");
    formData.append("file", fileOfBlob);
    const url = window.URL.createObjectURL(fileOfBlob);
    sendMessage(dataUrl, fileOfBlob);
  };
  // 将音频数据转换为 base64 编码的 data URL
  reader.readAsDataURL(blob);

  // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
  // const newbolb = new Blob([blob], { type: 'audio/wav' })
  // const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
  // formData.append('file', fileOfBlob);
  // console.log("这里进入了3");
  // const url = window.URL.createObjectURL(fileOfBlob)
  // recorder.stopPlay() // 停止播放
  // console.log("生成的url", url);
  //sendMessage(url, fileOfBlob);
};
export const handleMouseDown = async (
  event,
  setWidth,
  timeoutRef,
  intervalRef
) => {
  event.preventDefault();
  dwn_flag = 1; //按键按下
  recorder.destroyRecord(); //recorder必须清除之前的录音，由于其必须作为全局变量，所以这里要进行清除
  if (intervalRef.current) {
    //清除进度条
    clearInterval(intervalRef.current);
  }
  if (timeoutRef.current) clearTimeout(timeoutRef.current); //清除短延时设置

  const handleDenied = async ()=>{
     try {
        permit_flag = 1;
        await navigator.mediaDevices.getUserMedia({ audio: true });
        permit_flag = 0;
       return; // 权限申请后不进行录音
     } catch (error) {
       permit_flag = 0;
       return; // 权限申请后不进行录音
     }
    
  }

  const handleGranted = ()=>{
    timeoutRef.current = setTimeout(async () => {
      //设置延时5ms录音
      clearTimeout(timeoutRef.current); //这里已经调到回调了，肯定可以清除了
      timeoutRef.current = null; //与上面配套
      recorder.stopPlay(); // 停止播放
      recorder.initRecorder(); //录音机开始前必备
      try {
        recorder.stop(); // 停止可能存在的录音   
        recorder.start(); // 开始录音
      } catch (err) {
        console.log("err", err);
      }
      flag = 0; //调用up函数的标记，如果调用过，之后不用再执行up函数
      intervalRef.current = setInterval(() => {
        //这个位置设置进度条伸展
        setWidth((width) => {
          if (width == 200) {
            clearInterval(intervalRef.current);
            handleMouseUp();
            flag = 1; //打标调用过了
            cur_width = 0; //width不能及时变化，不得以用此变量
            return 0;
          } else {
            cur_width = cur_width + 1;
            return width + 1;
          }
        });
      }, 100);
    }, 500);
  }
  if (!("permissions" in navigator)) {
    deny_flag = 1;
    permit_flag = 0;
    if(isIOS){
        toast.info("检测到您的设备为ios，ios功能在测试中..", {
          position: "bottom-center",
        });
        var res = judgeIosPermission("record");
        if(res){
            deny_flag = 0;
            handleGranted();
        }
        else{
            handleDenied();
        }
    }
    else if(isAndroid){
         toast.info("检测到您的设备为安卓，安卓功能在测试中..", {
           position: "bottom-center",
         });
         var result = await requestAndroidPermission(
           "android.permission.RECORD_AUDIO"
         );
         toast.info("设备检查结果是.."+result, {
           position: "bottom-center",
         });
         if (result == 1) {
            deny_flag = 0;
            toast.info("安卓设备已经获取权限...." + result, {
              position: "bottom-center",
            });
           handleGranted();
         } else if (result == 0) {
            toast.info("安卓设备禁用权限了...." + result, {
              position: "bottom-center",
            });
           handleDenied();
         } else {
             toast.info("您已经永久拒绝权限!", {
               position: "bottom-center",
             });
         }
    }
    else{
      //此处为ios用户判断
      toast.info("您的平台不支持此API", {
        position: "bottom-center",
      });
      deny_flag = 1;
      return;
    }
   
  }

  navigator.permissions
    .query({ name: "microphone" })
    .then(async (permissionStatus) => {
      deny_flag = 0;
      permit_flag = 0;
      if (permissionStatus.state === "granted") {
       handleGranted();
      } else if (permissionStatus.state === "denied") {
        //此处权限被禁止了，不再弹出短时长的录制
        deny_flag = 1;
        toast.info("您未授予麦克风权限!", {
          position: "bottom-center",
        });
        handleDenied();
        // 权限已拒绝，无法执行相关操作
      } else {
       handleDenied();
      }
    });
};
