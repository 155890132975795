import AddChat from "./addChat";
import { API_URL } from '../config/config';
import uuid from "./uuid";
import addChats from "./addChats";

async function fetchDialogs(props) {
  try {

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const queryParams = new URLSearchParams({
      userId: userId,
      limit: props.addChats.limit,
      exclusiveStartKey: props.addChats.exclusiveStartKey
    });

    const response = await fetch(`${API_URL}/chatbot/dialogs?${queryParams}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });

    if (response.status === 400) {
      alert("非法用户")
      window.location.href = "/login";
      console.error("请求失败:", response.statusText);
      return null;
    }
    else {
      let data = await response.json();
      //sort the dialogs by time 
      let dialogs = data.dialogs;
      dialogs = dialogs.sort((a, b) => b.time - a.time);
      let i = 0;
      for (i = props.addChats.max_id; i < props.addChats.max_id + dialogs.length; i++) {
        const timex = dialogs[i - props.addChats.max_id].time;
        if (!props.addChats.timeStamp.hasOwnProperty(timex)) {
          const tmpId = uuid(8, 10);
          var x = {
            father: props.addChats,
            setCurChat: props.setCurChat,
            fatherRenderFunc: props.fatherRenderFunc,
            handleRenderHistory: props.handleRenderHistory,
            id: i,
            threadId: dialogs[i - props.addChats.max_id].threadId,
            title: dialogs[i - props.addChats.max_id].title,
            choose: false,
            persistId: tmpId
          };
          var tk = new AddChat(x);
          props.addChats.NewChat[i] = tk;
          props.addChats.timeStamp[timex] = 1;
        }
      }
      let lastEvaluatedKey = data.lastEvaluatedKey;
      if (dialogs.length > 0 && props.addChats.exclusiveStartKey == null) {
        props.setCurChat({ curChat: props.addChats.NewChat[0], oldCurId: -1 });
        props.addChats.NewChat[0].fatherRenderFunc({ change: true });
      }

      props.setCurChat({
        exclusiveStartKey: encodeURIComponent(JSON.stringify(lastEvaluatedKey)),
        oldCurId: -5,
        max_id: i
      })

      if (props.addChats.NewChat.hasOwnProperty(0))
        props.addChats.NewChat[0].fatherRenderFunc({ change: true });
      return data;
    }
  } catch (error) {
    console.error("请求错误:", error);
    return null;
  }
}

export default fetchDialogs;
